<template>
  <div class="relative">
    <div
      class="flex-mid"
      style="margin: auto; padding: 0 1.5rem; max-width: 500px"
    >
      <div style="margin-top: 10px; text-align: center">
        <!-- <img src="@/assets/image/doctor.png" style="height: 250px" /> -->
        <img src="@/assets/image/waiting.gif" style="height: 20rem" />

        <h1 style="margin-top: 2rem; font-size: 1.6rem">
          {{ $t("loading") }}
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
export default defineComponent({
  name: "PaymentWaitingCredit",
  // components: {
  //     Header: require("@/components/HomeNav.vue").default,
  // },
  data() {
    return {
      accessToken: "",
      isLoading: true,
      lang: "th",
      doctorPlan: null,
      totalPrice: 0,
      checkReload: false,
      orderId: "",
      chargeId: "",
      token: "",
      saveCard: false,
    };
  },
  mounted() {
    this.access_token = window.localStorage.getItem("access_token");
    this.orderId = window.localStorage.getItem("orderId");
    this.totalPrice = window.localStorage.getItem("totalPrice");
    this.token = this.$route.query.token;
    this.saveCard = this.$route.query.saveCard;
    console.log("language", this.lang);
    console.log(this.saveCard, this.token, this.orderId);
    this.setupPayment();

    // this.createPayment()
  },
  methods: {
    redirect(redirect) {
      window.location.href = redirect;
    },
    reloadPage() {
      this.checkReload = true;
      window.localStorage.setItem("checkReload", this.checkReload);
      window.location.reload();
    },
    setupPayment() {
      axios
        .post(
          process.env.VUE_APP_PAYMENTAPI_URL +
            "/well-payment-api/gateway/setup-payment",
          {
            setupPaymentRequestBean: {
              currency: "THB",
              description: "TotalHealth Package",
              orderDetailBean: [
                {
                  source: "TOTALSOL",
                  description: "TotalHealth Package",
                  orderNumber: this.orderId,
                  purchasePrice: parseInt(this.totalPrice),
                  quantity: 1,
                  deliveryFee: 0,
                },
              ],
              paymentMethod: "card",
              paymentType: "credit-card",
              rememberCard: this.saveCard,
              token: this.token,
              merchantId: "451331211230001",
            },
          },
          {
            headers: {
              // 'x-api-key': HEALTH_DIARY_API_KEY,
              Authorization: "Bearer " + this.access_token,
              accept: "application/json; charset=UTF-8",
              "Accept-Language": this.lang,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          let redirectPage = res.data.setupResponse;
          this.chargeId = res.data.transaction.chargeId;
          console.log(redirectPage);
          if (redirectPage != null) {
            window.setTimeout(function () {
              window.location.href = redirectPage;
            }, 4000);
          } else {
            this.createPayment();
          }
        })
        .catch((error) => {
          this.Loading = false;
          if (error.response == null) {
            this.errmsg = error;
          } else if (error.response.status == 403) {
            window.location = "/signin";
          } else {
            if (error.response.data.error) {
              this.errmsg = error.response.data.error;
            } else {
              this.errmsg = error;
            }
          }
        });
    },
    createPayment() {
      axios
        .post(
          process.env.VUE_APP_PAYMENTAPI_URL +
            "/well-payment-api/gateway/create-payment",
          {
            createPaymentRequestBean: {
              paymentType: "credit-card",
              chargeId: this.chargeId,
              orderId: "",
              token: this.token,
              rememberCard: this.saveCard,
            },
          },
          {
            headers: {
              // 'x-api-key': HEALTH_DIARY_API_KEY,
              Authorization: "Bearer " + this.access_token,
              accept: "application/json; charset=UTF-8",
              "Accept-Language": this.lang,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("create payment", res.data);
          this.redirect("/payment-credit-success");
        })
        .catch((error) => {
          this.Loading = false;
          if (error.response == null) {
            this.errmsg = error;
          } else if (error.response.status == 403) {
            window.location = "/signin";
          } else {
            if (error.response.data.error) {
              this.errmsg = error.response.data.error;
            } else {
              this.errmsg = error;
            }
          }
        });
    },
  },
});
</script>

<style lang="scss" scoped>
$color-on-hover: #fbfff5;

html,
body {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

.hospital-name {
  font-size: 1.4rem;
}

.fast-pay-button {
  width: 30%;
  height: 4rem;
  background-color: #429928;
  color: #ffffff;
  font-size: 1.8rem;
  text-align: center;
  border-radius: 2rem;
}
</style>
